export default function () {
  const rows = document.querySelectorAll('.orders .table__row');
  rows.forEach((row) => {
    if (row.querySelector('strong')) {
      row.querySelector('strong').addEventListener('click', () => {
        const tableContent = row.closest('.orders').querySelector('.table__content');
        const contentHeight = row.closest('.table__row').querySelector('.table__row-content');
        if (row.classList.contains('table__row--active')){
          row.classList.remove('table__row--active');
          contentHeight.style.maxHeight = 0;
          tableContent.style.maxHeight = '100%';
        } else {
          row.classList.add('table__row--active');
          tableContent.style.maxHeight = `${contentHeight.scrollHeight + parseInt(tableContent.offsetHeight, 10)}px`;
          contentHeight.style.maxHeight = `${contentHeight.scrollHeight}px`;
        }
      })
    }
  });
  if (document.querySelector('#all')) {
    const selectAll = document.querySelector('#all');
    selectAll.addEventListener('click', (e) => {
      const selectAllVal = e.target.checked;
      const checkboxes = e.target.closest('.orders').querySelectorAll('input[type=checkbox]');
      if (selectAllVal === true) {
        checkboxes.forEach((check) => {
          check.checked = true;
        })
      } else {
        checkboxes.forEach((check) => {
          check.checked = false;
        })
      }
    });
  }
}
