export default function () {
  const productsButtons = [document.querySelector('#products-mobile'), document.querySelector('.level--1 span')];
  const aboutButtons = [document.querySelector('#about-mobile'), document.querySelector('.level--about span')];
  const levelAbout = document.querySelector('.level--about');
  const levelZero = document.querySelector('.level--0');
  const levelOne = document.querySelector('.level--1');
  const menuTabs = document.querySelectorAll('[data-menu-target]');
  const menuTabContents = document.querySelectorAll('[data-menu-content]');
  const mobileMenuFooter = document.querySelector('.mobile-menu__footer');
  const toggleFooter = () => {
    if (!levelZero.classList.contains('hidden')) {
      mobileMenuFooter.classList.remove('hidden');
      return;
    }
    mobileMenuFooter.classList.add('hidden');
  };
  productsButtons.forEach((el) => {
    el.addEventListener('click', () => {
      levelZero.classList.toggle('hidden');
      levelOne.classList.toggle('hidden');
      toggleFooter();
    });
  });
  aboutButtons.forEach((el) => {
    el.addEventListener('click', () => {
      levelZero.classList.toggle('hidden');
      levelAbout.classList.toggle('hidden');
      toggleFooter();
    });
  });
  menuTabs.forEach((tab) => {
    tab.addEventListener('click', () => {
      const target = document.querySelector(tab.dataset.menuTarget);
      menuTabContents.forEach((tabContent) => {
        tabContent.classList.remove('active');
        tabContent.classList.add('hidden');
      });
      menuTabs.forEach((item) => {
        item.classList.remove('active');
        // tab.classList.add('hidden');
      });
      tab.classList.add('active');
      target.classList.add('active');
      tab.classList.remove('hidden');
      target.classList.remove('hidden');
      toggleFooter();
    });
  });
  document.querySelectorAll('.level--1 li').forEach((el) => {
    el.addEventListener('click', (e) => {
      if (!e.target.dataset.menuTarget) {
        return;
      }
      levelOne.classList.toggle('hidden');
      toggleFooter();
    });
  });

  document.querySelectorAll('.level--2 span').forEach((el) => {
    el.addEventListener('click', () => {
      const parent = el.parentNode.parentNode;
      parent.classList.remove('active');
      parent.classList.toggle('hidden');
      levelOne.classList.toggle('hidden');
      toggleFooter();
    });
  });
}
